import React from "react";
import { Box, Typography } from "@mui/material";
import welcomeImage1 from "../../assets/welcome1.webp";
import welcomeImage2 from "../../assets/welcome2.webp";

function WelcomeAbout() {
  return (
    <Box
      sx={{
        maxWidth: "800px",
        margin: "auto",
        paddingBlock: "100px",
      }}
    >
      {/* Top Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        {/* Left Section: Styled Years of Experience */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              gap: "10px",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: "bold",
                color: "#ffc107",
                fontSize: { xs: "40px", md: "60px" },
              }}
            >
              5
            </Typography>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "#00264d",
                  fontSize: { xs: "20px", md: "30px" },
                  lineHeight: 1,
                }}
              >
                Års
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "#00264d",
                  fontSize: { xs: "20px", md: "30px" },
                  lineHeight: 1,
                }}
              >
                Erfarenhet
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Right Section: Welcome Message */}
        <Box
          sx={{
            flex: 2,
            color: "black",
            textAlign: "left",
            px: 2,
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: "10px" }}>
            LD Ekonomi & Konsult går på djupet för att upptäcka insikter och har
            modet att agera.
          </Typography>
          <Typography variant="body1">
            LD Ekonomi & Konsult sammanför rätt resurser för att utmana
            etablerade tankesätt och driva förändring. Tillsammans med kunder
            bygger LD Ekonomi & Konsult de förmågor som gör det möjligt för
            organisationer att uppnå hållbara konkurrensfördelar. Tillsammans
            formar LD Ekonomi & Konsult framtiden.
          </Typography>
        </Box>
      </Box>

      {/* Bottom Section: Two Images */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          gap: "20px",
          marginTop: "50px",
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
          }}
        >
          <img
            src={welcomeImage1}
            alt="LD Ekonomi & Konsult - bokföring och affärskonsulttjänster"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
          }}
        >
          <img
            src={welcomeImage2}
            alt="LD Ekonomi & Konsult - bokföring och affärskonsulttjänster"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default WelcomeAbout;
