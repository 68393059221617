import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import {
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaMapMarkerAlt,
} from "react-icons/fa";
import backgroundImage from "../assets/7.webp";
import logo from "../assets/logo.svg";

function Footer() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        position: "relative",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        padding: "40px 20px 20px 20px",
        textAlign: "center",
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(11, 17, 24, 0.95)", // Semi-transparent dark overlay
          zIndex: 1,
        }}
      ></Box>

      {/* Main Content */}
      <Box sx={{ position: "relative", zIndex: 2 }}>
        {/* Main Row */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr 1fr", md: "repeat(4, 1fr)" },
            gap: "20px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          {/* Column 1: Company Description */}
          <Box>
            <Box sx={{ textAlign: "left" }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: "10px" }}
              >
                LD Ekonomi & Konsult
              </Typography>
              <Typography variant="body2">
                Stärk ditt företag med LD Ekonomis mångsidiga konsultlösningar.
                Låt oss omvandla din vision till verklighet!
              </Typography>
            </Box>
          </Box>

          {/* Column 2: Links */}
          <Box>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              Länkar
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Link
                href="/"
                underline="none"
                onClick={() => window.scrollTo(0, 0)}
                color="white"
                sx={{ fontSize: "14px", "&:hover": { color: "#ffc107" } }}
                title="Go to homepage"
              >
                Hem
              </Link>
              <Link
                href="/about"
                underline="none"
                onClick={() => window.scrollTo(0, 0)}
                color="white"
                sx={{ fontSize: "14px", "&:hover": { color: "#ffc107" } }}
                title="Go to About page"
              >
                Om oss
              </Link>
              <Link
                href="/services"
                underline="none"
                onClick={() => window.scrollTo(0, 0)}
                color="white"
                sx={{ fontSize: "14px", "&:hover": { color: "#ffc107" } }}
                title="Go to Services page"
              >
                Tjänster
              </Link>
              <Link
                href="/contact"
                underline="none"
                onClick={() => window.scrollTo(0, 0)}
                color="white"
                sx={{ fontSize: "14px", "&:hover": { color: "#ffc107" } }}
                title="Go to contact page"
              >
                Kontakt
              </Link>
            </Box>
          </Box>

          {/* Column 3: Logo and Social Icons */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column", // Align items in a single column
              alignItems: "left", // Center items horizontally
              gap: "20px", // Space between logo and social icons
            }}
          >
            {/* Logo */}
            <Box
              sx={{
                width: "150px",
              }}
            >
              <img
                src={logo}
                alt="Logotyp för LD Ekonomi & Konsult - redovisning och konsulttjänster i Sverige."
                style={{
                  width: "100%",
                  objectFit: "contain",
                  cursor: "pointer",
                  transition: "transform 0.3s, opacity 0.3s",
                }}
                onClick={() => (window.location.href = "/")} // Redirect to homepage when logo is clicked
              />
            </Box>
          </Box>

          {/* Column 4: Address and Contact Info */}
          <Box>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              Kontakt
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginBottom: "5px",
              }}
            >
              <FaMapMarkerAlt />
              <Typography variant="body2" sx={{ fontSize: "14px" }}>
                Stockholm, 136 40 Haninge
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginBottom: "5px",
              }}
            >
              <FaPhoneAlt />
              <Typography variant="body2" sx={{ fontSize: "14px" }}>
                +46 76 82 76 009
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {/* Social Icons */}
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  size="small"
                  sx={{ color: "white" }}
                  component="a"
                  href="https://www.facebook.com/profile.php?id=61570455181423"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit our Facebook profile"
                  title="Visit our Facebook profile"
                >
                  <FaFacebookF />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://www.instagram.com/ld_ekonomi_konsult/"
                  aria-label="Instagram"
                  target="_blank"
                  sx={{
                    color: "white",
                    backgroundColor: "#004080",
                    "&:hover": { backgroundColor: "#0056b3" },
                  }}
                >
                  <FaInstagram />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Second Row: Footer Info */}
        <Box
          sx={{
            marginTop: "20px",
            fontSize: "12px",
            color: "gray",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            component={Link}
            href="https://samoteev.dev"
            title="Go to samoteev.dev page, the fullstack developer who created this beautiful website"
            target="_blank" // Open link in a new tab
            rel="noopener noreferrer" // Improve security
            underline="none"
            color="gray"
            sx={{ "&:hover": { color: "#ffc107" } }}
          >
            © 2024 LD Ekonomi & Konsult. Alla rättigheter förbehållna.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
