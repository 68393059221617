import { Box } from "@mui/material";
import ContactComponent from "../components/contact/ContactComponent";
import HeroContact from "../components/contact/HeroContact";
import ServicesContact from "../components/contact/ServicesContact";

export default function Contact() {
  return (
    <Box>
      <HeroContact />
      <ContactComponent />
      <ServicesContact />
    </Box>
  );
}
