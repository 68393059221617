import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", serif',
    h6: {
      fontSize: "16px", // Smaller size for h6
      fontWeight: 500, // You can adjust the font weight if needed
      lineHeight: 1.5, // Adjust line height for better spacing
    },
    body3: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "gray",
    },
  },
});

export default theme;
