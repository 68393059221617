import { Routes, Route } from "react-router-dom";

import "./App.css";
import Home from "./pages/Home";
import Navbar from "./components/NavBar";
import { Box } from "@mui/material";
import UpperNavbar from "./components/UpperNavBar";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme.js";
import Footer from "./components/Footer.js";
import About from "./pages/About.js";
import Services from "./pages/Services.js";
import Contact from "./pages/Contact.js";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <UpperNavbar />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
