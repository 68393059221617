import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useInView } from "react-intersection-observer";
import backgroundImage from "../../assets/6.webp";
import teamImage from "../../assets/1.webp";

function Mission() {
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation once
    threshold: 0.1, // Start animation when 10% of the element is in view
  });

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
        height: "auto",
        minHeight: "50vh",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Left Section: Background with Overlay and Accordion */}
      <Box
        sx={{
          flex: 1, // Makes both sections take equal space
          height: "auto",
          position: "relative",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          transform: inView ? "translateX(0)" : "translateX(-100%)",
          opacity: inView ? 1 : 0,
          transition: "transform 1s ease, opacity 1s ease",
          paddingBlock: 2,
        }}
      >
        {/* Overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(11, 17, 24, 0.8)", // Dark overlay
          }}
        ></Box>

        {/* Accordion Content */}
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            padding: "20px",
            maxWidth: "400px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Accordion
            sx={{
              backgroundColor: "white", // Default white background
              color: "#00264d",
              boxShadow: "none",
              "&.Mui-expanded": {
                backgroundColor: "#ffc107", // Yellow when expanded
                color: "#00264d",
              },
              "&:not(:last-child)": {
                marginBottom: "10px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#00264d" }} />}
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              01 Vår Mission
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "white",
                color: "#00264d",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography>
                När du arbetar med en helt digital och fjärrstyrd
                redovisningsexpert får du enkel och flexibel tillgång till
                expertis. Vi ser till att ditt företag har rätt rutiner, korrekt
                hantering och smidiga processer – allt från vilken plats som
                helst, när som helst.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              backgroundColor: "white", // Default white background
              color: "#00264d",
              boxShadow: "none",
              "&.Mui-expanded": {
                backgroundColor: "#ffc107", // Yellow when expanded
                color: "#00264d",
              },
              "&:not(:last-child)": {
                marginBottom: "10px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#00264d" }} />}
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              02 Vår Vision
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "white",
                color: "#00264d",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography>
                Att vara den ledande partnern som levererar innovativa och
                digitala lösningar för företag världen över. Med en helt
                fjärrbaserad struktur kan vi fokusera på att skapa effektiva
                lösningar anpassade för din framgång, oavsett var du befinner
                dig.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              backgroundColor: "white", // Default white background
              color: "#00264d",
              boxShadow: "none",
              "&.Mui-expanded": {
                backgroundColor: "#ffc107", // Yellow when expanded
                color: "#00264d",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#00264d" }} />}
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              03 Våra Värderingar
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "white",
                color: "#00264d",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography>
                Digital integritet, innovation och samarbete ligger till grund
                för allt vi gör. Genom vår helt digitala arbetsmetod prioriterar
                vi säkerhet och effektivitet samtidigt som vi upprätthåller
                högsta kvalitet i alla våra tjänster.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>

      {/* Right Section: Team Image */}
      <Box
        sx={{
          flex: 1, // Makes both sections take equal space
          position: "relative",
          backgroundImage: `url(${teamImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: { xs: "200px", md: "auto" },
          transform: inView ? "translateX(0)" : "translateX(100%)",
          opacity: inView ? 1 : 0,
          transition: "transform 1s ease, opacity 1s ease",
        }}
      ></Box>
    </Box>
  );
}

export default Mission;
