import React, { useState } from "react";
import { Box, Typography, IconButton, Avatar } from "@mui/material";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import avatar1 from "../../assets/reviews/teamett.png";
import avatar3 from "../../assets/reviews/gvs.png";
import avatar4 from "../../assets//reviews/logo-pvc.webp";
import avatar5 from "../../assets/reviews/dmbyggstad-logo-wide-350x77-1.png";
import avatar6 from "../../assets/reviews/JLD-Bygg-logga-300x99.png";

import { BiSolidQuoteRight } from "react-icons/bi";

// Reviews Data
const reviews = [
  {
    name: "TeamEtt",
    role: "Beauty Salong",
    avatar: avatar1,
    quote:
      "LD Ekonomi & Konsult har varit en riktig game-changer för vårt företag. Deras expertis och smidiga digitala lösningar har effektiviserat vår ekonomi och gett oss tid att fokusera på tillväxt. Pålitliga, professionella och alltid steget före – varmt rekommenderade!",
  },
  {
    name: " GVS Riv AB  ",
    role: "Golvvärmespecialisten",
    avatar: avatar3,
    quote:
      "LD Ekonomi & Konsult har varit ovärderliga för vårt företag. Deras noggranna bokföring och effektiva tjänster har gett oss tid att fokusera på det vi gör bäst. Rekommenderas varmt!",
  },
  {
    name: " PVS Bröderna AB",
    role: "Takföretag ",
    avatar: avatar4,
    quote:
      "LD Ekonomi & Konsult har hjälpt oss att få ordning på vår ekonomi och förenklat våra administrativa processer. Deras expertis och professionalism gör att vi känner oss trygga och kan fokusera på våra projekt. Stort tack för ett fantastiskt samarbete!",
  },
  {
    name: "DM Bygg & Städ AB",
    role: "Contractor in Draget",
    avatar: avatar5,
    quote:
      "Vi uppskattar den noggranna och smidiga hanteringen av vår bokföring och löneadministration. Deras hjälp har sparat oss både tid och resurser, vilket låtit oss fokusera på att leverera våra tjänster. Rekommenderas varmt!",
  },
  {
    name: "JLD Bygg & Entreprenad AB ",
    role: "Bygg",
    avatar: avatar6,
    quote:
      "Varm rekommendation från JLD Bygg & Entreprenad AB. Vi på JLD Bygg & Entreprenad AB är väldigt nöjda med LD Ekonomi & Konsult. Deras noggrannhet och personliga engagemang har gjort vår ekonomi smidig och enkel. Vi rekommenderar dem varmt! 🌟",
  },
];

function Reviews() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  return (
    <Box
      sx={{
        maxWidth: "800px",
        margin: "0 auto",
        textAlign: "center",
        py: 8,
        px: 6,
      }}
    >
      <BiSolidQuoteRight
        style={{
          fontSize: "48px", // Adjust size
          color: "#ffc107", // Yellow color
          marginBottom: "8px", // Add spacing below the icon
        }}
      />{" "}
      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          mb: 4,
          color: "#00264d",
        }}
      >
        Vad de säger
      </Typography>
      {/* Quote */}
      <Box
        sx={{
          position: "relative",
        }}
      >
        <IconButton
          onClick={handlePrev}
          sx={{
            position: "absolute",
            top: "50%",
            left: "-50px",
            transform: "translateY(-50%)",
            backgroundColor: "#f5f5f5",
            "&:hover": { backgroundColor: "#ddd" },
          }}
        >
          <FaChevronLeft size="12px" />
        </IconButton>

        <Typography
          variant="body1"
          sx={{
            fontStyle: "italic",
            color: "gray",
            mb: 4,
            px: 2,
          }}
        >
          “ {reviews[currentIndex].quote} ”
        </Typography>

        <IconButton
          onClick={handleNext}
          sx={{
            position: "absolute",
            top: "50%",
            right: "-50px",
            transform: "translateY(-50%)",
            backgroundColor: "#f5f5f5",
            "&:hover": { backgroundColor: "#ddd" },
          }}
        >
          <FaChevronRight size="12px" />
        </IconButton>
      </Box>
      {/* Avatar */}
      <Avatar
        src={reviews[currentIndex].avatar}
        alt="LD Ekonomi & Konsult - bokföring och affärskonsulttjänster"
        sx={{
          width: "150px",
          height: "150px",
          margin: "0 auto",
          padding: 1,
          mb: 2,
          "& img": {
            objectFit: "contain", // Ensure the image inside the Avatar fits well
          },
        }}
      />
      {/* Name */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          color: "#00264d",
        }}
      >
        {reviews[currentIndex].name}
      </Typography>
      {/* Role */}
      <Typography
        variant="body2"
        sx={{
          color: "#ffc107",
        }}
      >
        {reviews[currentIndex].role}
      </Typography>
    </Box>
  );
}

export default Reviews;
