import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import logo from "../assets/circle.svg"; // Default logo

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Change to true if user scrolls down 50px
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="sticky"
      sx={{
        paddingInline: "none",
        backgroundColor: isScrolled ? "#00264d" : "white",
        color: isScrolled ? "white" : "#071324",
        boxShadow: "none",
        transition: "background-color 0.3s, color 0.3s",
      }}
    >
      <Toolbar>
        {/* Left side: Navigation links */}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-start",
            gap: {
              xs: "10px", // Small gap on XS screens
              md: "20px", // Larger gap on MD screens
            },
          }}
        >
          <Link
            to="/"
            onClick={() => window.scrollTo(0, 0)}
            style={{
              textDecoration: "none",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              color: isScrolled ? "white" : "#071324",
              transition: "color 0.3s",
            }}
          >
            Hem
          </Link>
          <Link
            to="/about"
            onClick={() => window.scrollTo(0, 0)}
            style={{
              textDecoration: "none",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              color: isScrolled ? "white" : "#071324",
              transition: "color 0.3s",
            }}
          >
            Om oss
          </Link>
          <Link
            to="/services"
            onClick={() => window.scrollTo(0, 0)}
            style={{
              textDecoration: "none",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              color: isScrolled ? "white" : "#071324",
              transition: "color 0.3s",
            }}
          >
            Tjänster
          </Link>
          <Link
            to="/contact"
            onClick={() => window.scrollTo(0, 0)}
            style={{
              textDecoration: "none",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              color: isScrolled ? "white" : "#071324",
              transition: "color 0.3s",
            }}
          >
            Kontakt
          </Link>
        </Box>

        {/* Right side: Logo */}
        <Box
          sx={{
            backgroundColor: "#00264d",

            display: "flex",
            alignItems: "center",
            width: { xs: "60px", md: "70px" },
            height: { xs: "60px", md: "70px" },
            borderRadius: "50%",
          }}
        >
          <img
            src={logo}
            alt="Logotyp för LD Ekonomi & Konsult - redovisning och konsulttjänster i Sverige."
            style={{
              width: "100%",
              objectFit: "contain",
              cursor: "pointer",
              transition: "transform 0.3s, opacity 0.3s",
            }}
            onClick={() => (window.location.href = "/")} // Redirect to homepage when logo is clicked
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
