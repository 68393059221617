import React, { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import emailjs from "emailjs-com";

// Define environment variables at the top
const SERVICE_ID = process.env.REACT_APP_SERVICEID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATEID;
const USER_ID = process.env.REACT_APP_USERID;

function ContactComponent() {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    description: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    description: false,
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {
      name: formValues.name === "",
      email: formValues.email === "",
      description: formValues.description === "",
    };
    setFormErrors(errors);

    if (!Object.values(errors).includes(true)) {
      // Sending email through emailjs
      emailjs
        .send(
          SERVICE_ID,
          TEMPLATE_ID,
          {
            from_name: formValues.name,
            from_email: formValues.email,
            message: formValues.description,
          },
          USER_ID
        )
        .then((result) => {
          console.log(result.text);
          setSuccessMessage("Message successfully sent!");
          setErrorMessage("");
          setFormValues({ name: "", email: "", description: "" });
        })
        .catch((error) => {
          setErrorMessage("Failed to send the message. Please try again.");
          setSuccessMessage("");
        });
    }
  };

  return (
    <Box
      id="contact-form"
      sx={{
        maxWidth: "800px",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "100px 0",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
          gap: "40px",
          maxWidth: "1200px",
          width: "100%",
        }}
      >
        {/* Left Column: Information */}
        <Box sx={{ paddingInline: "20px" }}>
          <Typography
            variant="h6"
            sx={{
              color: "#ffc107",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Hjälp att växa
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "#071324",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Vi hjälper dig att utveckla ditt företag och nå dina mål.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#555",
              marginBottom: "20px",
            }}
          >
            På LD Ekonomi & Konsult är vi mer än bara redovisning — vi är en
            partner för din framgång. Med våra tjänster får du skräddarsydda
            lösningar för att hantera utmaningar och skapa tillväxt.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#071324",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Stockholm
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <FaMapMarkerAlt color="#ffc107" />
            <Typography variant="body2" sx={{ color: "#555" }}>
              Stockholm, 136 40 Haninge
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <FaPhoneAlt color="#ffc107" />
            <Typography variant="body2" sx={{ color: "#555" }}>
              +46 76 82 76 009
            </Typography>
          </Box>
        </Box>

        {/* Right Column: Form */}
        <Box
          sx={{
            backgroundColor: "#f4f8fb",
            padding: "30px",
            alignContent: "center",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#071324",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Skicka ett meddelande
          </Typography>
          <form onSubmit={handleSubmit}>
            {/* Name Field */}
            <TextField
              placeholder="Your name"
              name="name"
              variant="standard"
              fullWidth
              value={formValues.name}
              onChange={handleChange}
              error={formErrors.name}
              helperText={formErrors.name ? "Name is required" : ""}
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                },
              }}
            />
            {/* Email Field */}
            <TextField
              placeholder="Your email"
              type="email"
              name="email"
              variant="standard"
              fullWidth
              value={formValues.email}
              onChange={handleChange}
              error={formErrors.email}
              helperText={formErrors.email ? "Email is required" : ""}
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                },
              }}
            />
            {/* Message Field */}
            <TextField
              placeholder="Your text"
              name="description"
              variant="standard"
              multiline
              rows={4}
              fullWidth
              value={formValues.description}
              onChange={handleChange}
              error={formErrors.description}
              helperText={formErrors.description ? "Message is required" : ""}
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#ffc107",
                color: "black",
                padding: "10px 0",
                fontWeight: "bold",
                textTransform: "none",
                borderRadius: "5px",
                "&:hover": {
                  backgroundColor: "#e0a800",
                },
              }}
            >
              Skicka
            </Button>
          </form>

          {/* Display success or error messages */}
          {successMessage && (
            <Typography
              sx={{ color: "green", marginTop: "20px", textAlign: "center" }}
            >
              {successMessage}
            </Typography>
          )}
          {errorMessage && (
            <Typography
              sx={{ color: "red", marginTop: "20px", textAlign: "center" }}
            >
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ContactComponent;
