import { Box } from "@mui/material";
import HeroAbout from "../components/aboutComponents/HeroAbout";
import WelcomeAbout from "../components/aboutComponents/WelcomeAbout";
import Relationship from "../components/aboutComponents/Relationship";
import ContactAbout from "../components/aboutComponents/ContactAbout";

export default function About() {
  return (
    <Box>
      <HeroAbout />
      <WelcomeAbout />
      <Relationship />
      <ContactAbout />
    </Box>
  );
}
