import { Box, Typography } from "@mui/material";
import Hero from "../components/homeComponents/Hero";
import ServicesHome from "../components/homeComponents/ServicesHome";
import Purpose from "../components/homeComponents/Purpose";
import Reviews from "../components/homeComponents/Reviews";
import ContactHome from "../components/homeComponents/ContactHome";
import Mission from "../components/homeComponents/Mission";

export default function Home() {
  return (
    <Box>
      <Typography>
        <Hero />
        <ServicesHome />
        <Purpose />
        <Reviews />
        <Mission />
        <ContactHome />
      </Typography>
    </Box>
  );
}
