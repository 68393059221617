import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import contactImage1 from "../../assets/contact1.webp";
import contactImage2 from "../../assets/contact2.webp";
import contactImage3 from "../../assets/contact3.webp";

// Array of imported images
const images = [contactImage1, contactImage2, contactImage3];

function HeroContact() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollToContactForm = () => {
    const contactForm = document.getElementById("contact-form");
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "70vh",
        overflow: "hidden",
      }}
    >
      {/* Slider Images */}
      {images.map((image, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            transition: "opacity 1s ease-in-out",
            opacity: index === currentIndex ? 1 : 0,
          }}
        ></Box>
      ))}

      {/* Overlay Color */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(11, 17, 24, 0.6)", // Semi-transparent black overlay
        }}
      ></Box>

      {/* Text Content */}
      <Box
        sx={{
          position: "absolute",
          width: "80%",
          margin: "auto",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          color: "white",
          padding: "0 20px", // Add padding for smaller screens
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#ffc107",
            fontWeight: "bold",
            fontSize: {
              xs: "32px", // Smaller size for extra small screens
              md: "48px", // Default size for medium screens and above
            },
            textTransform: "uppercase",
            fontFamily: '"Montserrat", serif',
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
          }}
        >
          Kontakta Oss
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            maxWidth: "800px",
            margin: "auto",
            fontSize: {
              xs: "14px", // Smaller size for extra small screens
              sm: "16px", // Medium size for small screens
              md: "18px", // Default size for medium screens and above
            },
          }}
        >
          Vi finns här för att hjälpa dig. Hör av dig så diskuterar vi hur vi
          kan arbeta tillsammans för att nå dina mål.
        </Typography>
        <Button
          variant="contained"
          onClick={scrollToContactForm}
          sx={{
            mt: 4,
            backgroundColor: "#ffc107",
            color: "black",
            fontWeight: "bold",
            fontSize: {
              xs: "12px",
              md: "14px",
            },
            "&:hover": {
              backgroundColor: "#e0a800",
            },
          }}
        >
          Kontakt oss ↓
        </Button>
      </Box>
    </Box>
  );
}

export default HeroContact;
