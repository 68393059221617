import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { FaPhoneAlt, FaClock, FaFacebookF, FaInstagram } from "react-icons/fa";

function UpperNavbar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Hide UpperNavbar when scrolled
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (isScrolled) return null; // Hide UpperNavbar when scrolled

  return (
    <Box
      sx={{
        backgroundColor: "#00264d",
        color: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "5px 20px",
        fontSize: "12px",
      }}
    >
      {/* Left Section: Contact Info */}
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <FaPhoneAlt />
          <Typography> (46) 76 82 76 009 </Typography>
        </Box>
        <Divider />

        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <FaClock />
          <Typography> Mån–fre 7:00–20:00 </Typography>
        </Box>
      </Box>

      {/* Right Section: Social Media and Language */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <IconButton
          size="small"
          sx={{ color: "white" }}
          component="a"
          href="https://www.facebook.com/profile.php?id=61570455181423"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Visit our Facebook profile"
          title="Visit our Facebook profile"
        >
          <FaFacebookF />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.instagram.com/ld_ekonomi_konsult/"
          aria-label="Instagram"
          target="_blank"
          sx={{
            color: "white",
            backgroundColor: "#004080",
            "&:hover": { backgroundColor: "#0056b3" },
          }}
        >
          <FaInstagram />
        </IconButton>
      </Box>
    </Box>
  );
}

// Divider component to add vertical lines
const Divider = () => (
  <Box
    sx={{
      width: "1px",
      height: "20px",
      backgroundColor: "white",
      margin: "0 10px",
    }}
  />
);

export default UpperNavbar;
