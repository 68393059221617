import { Box } from "@mui/material";
import HeroServices from "../components/servicesComponents/HeroServices";
import ServicesOnPage from "../components/servicesComponents/ServicesOnPage";
import ContactAbout from "../components/aboutComponents/ContactAbout";

export default function Services() {
  return (
    <Box>
      <HeroServices />
      <ServicesOnPage />
      <ContactAbout />
    </Box>
  );
}
