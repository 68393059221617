import React from "react";
import { Box, Typography } from "@mui/material";

function Relationship() {
  const data = [
    { percentage: "91%", label: "Lyckade projekt" },
    { percentage: "100%", label: "Digitaliserade tjänster" },

    { percentage: "99%", label: "Nöjda kunder" },
  ];

  return (
    <Box
      sx={{
        maxWidth: "1200px",
        margin: "auto",
        textAlign: "center",
        paddingBottom: "60px",
      }}
    >
      {/* Subtitle */}
      <Typography
        variant="subtitle2"
        sx={{
          color: "#ffc107",
          fontWeight: "bold",
          textTransform: "uppercase",
          marginBottom: "10px",
        }}
      >
        Om oss{" "}
      </Typography>

      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "#00264d",
          marginBottom: "40px",
        }}
      >
        LD Ekonomi & Konsult bygger relationer
      </Typography>

      {/* Circles */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column", md: "row" },

          justifyContent: "space-around",
          gap: "30px",
        }}
      >
        {data.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* Circle with Percentage */}
            <Box
              sx={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                border: "5px solid #ffc107",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "#ffc107",
                }}
              >
                {item.percentage}
              </Typography>
            </Box>

            {/* Label */}
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: "#00264d",
              }}
            >
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Relationship;
