import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

function ContactAbout() {
  return (
    <Box sx={{ width: "100%", backgroundColor: "#041425f2" }}>
      <Box
        sx={{
          maxWidth: "800px",
          textAlign: "center",
          py: "100px",
          px: 2,

          margin: "auto",

          alignContent: "center",
        }}
      >
        {/* Company Tagline */}
        <Typography
          variant="body1"
          sx={{
            color: "#ffc107", // Yellow color
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Skaffa LD Ekonomi & Konsult idag!{" "}
        </Typography>

        {/* Heading */}
        <Typography
          variant="h4"
          sx={{
            color: "#ffffff", // Dark blue color
            fontWeight: "bold",
            mb: 4,
          }}
        >
          Effektiva lösningar för att driva ditt företags framgång.{" "}
        </Typography>

        {/* Contact Button */}
        <Button
          variant="contained"
          component={Link}
          onClick={() => window.scrollTo(0, 0)}
          to="/contact"
          sx={{
            mt: 4,
            backgroundColor: "#ffc107", // Yellow color
            color: "black",
            fontWeight: "bold",
            fontSize: {
              xs: "12px", // Smaller button text for extra small screens
              md: "14px", // Default size for medium screens and above
            },
            "&:hover": {
              backgroundColor: "#e0a800",
            },
          }}
          href="#contact"
          title="Go to contact page"
        >
          Kontakta oss →
        </Button>
      </Box>
    </Box>
  );
}

export default ContactAbout;
